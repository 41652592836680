<template>
  <div class="container login-container">
    <div class="container login ">
      <div class="row">
        <div class="col-12">
          <img src="@/img/logo.svg" alt="Logo" class="logo">
          <form>
          <label>E-Mail</label><br>
          <input type="text" name="mail" v-model="form.username"><br>
          <label>Password</label><br>
          <input type="text" name="password" v-model="form.password"><br>
          </form>
        </div>
        <div class="col-6">
          <router-link to="/" class="login-links">Create Account</router-link>
        </div>
        <div class="col-6 d-flex">
          <router-link to="/" class="login-links ms-auto">Forgot Password?</router-link>
        </div>
         <button  @click="Submit" type="submit" class=" col-4 mt-4 ms-2 login-btn aqua-button">Login</button>
      </div>
    </div>
    <!-- <img src="@/img/illustration1.svg" alt="Login"> -->
    <div class="login-image"></div>
  </div>

</template>

<script>
  import { mapActions } from "vuex"
  import { mapGetters } from 'vuex'
  import axios from 'axios'

  export default {
    name: "Login",

    data () {
      return {
        form: {
          username: "",
          password: "",
        },
      }
    },

    computed: {
      ...mapGetters({
      token: "AccessToken"
    }),
    },

    methods: {
      ...mapActions(["LogIn"]),

      async Submit () {
        const instance = axios.create({
          withCredentials: true,
          auth: {
            username: this.form.username,
            password: this.form.password,
          },
        })

        try {
            await this.LogIn(instance);
            this.$router.push("/homepage");
        } catch (error) {
          console.log(error)
        }
      },
    },
  }
</script>
  
<style>
  .container.login-container {
    display: flex;
    align-items: center;
    margin-right: 0;
    padding-right: 0;
    height: 100%;
  }

  .login-container .logo img,
  img.logo {
    height: 30px;
    position: absolute;
    top: 50px;
    /* left: 150px; */
  }

  /* .login-container img {
    height: 100%;
    min-width: 50vw;
    display: block;
  } */

  .login-container .login {
    max-width: 400px;
  }
  
  .login-container .login input,
  input,
  option,
  select {
    margin-bottom: 20px;
    width: 100%;
    height: 40px;
    border: 1px solid #dcdcdc;
    border-radius: 15px;
  }

  .login-container .login p {
    margin-bottom: 2px;
  }

  .login-container .login .login-links {
     color: #1E5E71;
  }

  .login-container .login .login-btn {
    margin-bottom: 20px;
  }

  .login-image {
    background-image: url("../img/illustration1.svg");
    height: 100vh;
    min-width: 50vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: block;
  }

  @media (max-width: 768px) {
    .login-container img,
    .login-image {
      display: none;
    }

    .login-container .login {
    max-width: 400px;
    height: 100vh;
    margin-top: 50px;
  }
  }
</style>
