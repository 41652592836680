import Vue from 'vue'
import store from '@//store'
import App from '@/App'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import BootstrapVue from 'bootstrap-vue'
import router from '@/router'
import VueResource from 'vue-resource'
import axios from 'axios'

axios.defaults.withCredentials = true
axios.defaults.baseURL = 'https://localhost:5000/api/'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(VueResource)

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
