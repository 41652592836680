<template>
  <div class="container dashboard">
    <div class="row justify-content-around">
      <div class ="col-md-6 order-1 request-card">
        <NewCredit></NewCredit>
      </div>
      <div class ="col-md-6 order-2 request-card">
        <PendingCredits></PendingCredits>
      </div>
      <div class ="row order-2 request-card">
        <HistoryCredit></HistoryCredit>
      </div>
    </div>
  </div>
</template>

<script>
  import NewCredit from "@/views/NewCredit"
  import PendingCredits from "@/views/PendingCredits"
  import HistoryCredit from "@/views/HistoryCredit"
  import { mapGetters } from "vuex"
  import { mapActions } from "vuex"
  import axios from "axios"

  export default {
    name: "Dashboard",
    
    computed: {
      ...mapGetters ({
        token: "AccessToken",
      })
    },

    methods: {
      ...mapActions(["Credits"]),

      async loadCredits () {
        try {
          await this.Credits();
          
        } catch (error) {
          console.log(error)
        }
      },
    },

    beforeMount () {
      this.loadCredits ()
    },

    components: {
      NewCredit,
      PendingCredits,
      HistoryCredit
    },
  }
</script>

<style>

  .dashboard-container {
    border: 1px solid #dcdcdc;
    padding: 50px;
    background-color: #fff; 
    border-radius: 15px;
    box-shadow: 0px 1px 5px rgba(196, 196, 196, 0.5);
    height: 100%;
  }

  .dashboard {
    margin-top: 50px;
  }

  .dashboard .aqua-button {
    max-width: 200px;
  }

  .img-replace ,
  .credit-container img.dashboard-img{
    height: 180px;
    margin: 20px 0;
    /* border: 1px solid grey; */
  }

</style>
