<template>
  <div class="body">
    <Navbar v-if="isNewRequest()"></Navbar>
    <Navbar v-if="isDashboard()"></Navbar>
    <!-- <Navbar v-if="isBaseRoute"></Navbar> TODO: wieder löschen -->
    <Login v-if="isBaseRoute()" class="container"></Login>
    <!-- <div class="container">
        <router-view></router-view>
    </div> -->
    <NewRequest v-if="isNewRequest()"></NewRequest>
    <Dashboard v-if="isDashboard()"></Dashboard>
    <CreditOverview v-if="isCreditOverview()"></CreditOverview>
  </div>
</template>

<script>
import Login from '@/views/Login'
import Navbar from '@/views/Navbar'
import NewRequest from '@/views/NewRequest'
import Dashboard from '@/views/Dashboard.vue'
import CreditOverview from '@/views/CreditOverview.vue'
export default {
  name: 'App',

  methods: {
    isBaseRoute () {
      return this.$route.path === "/"
    },
    isDashboard () {
      return this.$route.path === "/homepage"
    },
    isNewRequest () {
      return this.$route.path === "/new-request"
    },
    isCreditOverview() {
      return this.$route.path === "/current-credit"
      }
  },

  components: { 
    Login,
    Navbar, 
    NewRequest,
    Dashboard,
    CreditOverview
  },
}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* { 
  font-family: Work Sans;
  font-weight: 400;
  font-size: 14px;
  color: hsl(194, 58%, 28%);
}

.body {
  background: #FAFAFA;
  min-height: 100vh;
}

a {
  color: #1E5E71;
}

.aqua-button {
  background: #41BFDA;
  color: #FFF;
  border-radius: 15px;
  height: 40px;
  border: none;
  padding: 0 40px;
}

.aqua-button:hover {
  background: #B1EAA4;
}


</style>