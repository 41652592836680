<template>
  <div class="container new-request-container">
    <h3>Create a new request</h3>
    <form class="mt-5 request-form form-group">
      <div class="row justify-content-between">
        <div class="col-6">
          <p>Title</p>
          <input v-model="formData.title">
        </div>
        <div class="col-6">
          <p>Description</p>
          <input v-model="formData.description">
        </div>
      </div>
      <div class="mt-4 row justify-content-between">
        <div class="col-6">
           <p>Identification</p>
        </div>
        <div class="col-6 right-text">
          <button class="aqua-button">+</button>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <p>Name</p>
          <input v-model="formData.name">
        </div>
        <div class="col-6">
          <p>Surname</p>
          <input v-model="formData.surname">
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <p>Birthdate</p>
          <input v-model="formData.birthdate">
        </div>
        <div class="col-6">
          <p>Nationality</p>
          <input v-model="formData.nationality">
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p>Address</p>
          <input v-model="formData.address">
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-6">
          <p>Income</p>
        </div>
        <div class="col-6 right-text">
          <button class="aqua-button">+</button>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <p>Net Income</p>
          <input v-model="formData.netIncome">
        </div>
        <div class="col-6">
          <p>Expenses</p>
          <input v-model="formData.expenses">
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <p>Other Credits</p>
          <input v-model="formData.otherCredits">
        </div>
        <div class="col-6"></div>
      </div>
      <div class="row">
        <div class="col-6">
          <select name="mitarbeiter" @change="firstDropdownOnChange($event)" class="form-control">
              <option 
                  v-for="(item, index) in employees" 
                  :key="index"
                  :value="item"
                  style=" :before{ content: url('imgEmployees[index])' } "
                  >
                  {{item}}
              </option>
            </select>
        </div>
        <div class="col-6">
          <select name="mitarbeiter" @change="secondDropdownOnChange($event)" class="form-control">
              <option 
                  v-for="(item, index) in employees" 
                  :key="index"
                  :value="item"
                  >
                   {{item}}
              </option>
            </select>
        </div>
      </div>
      <div class="row mt-5 mb-3">
        <div class="col-6"></div>
        <div class="col-6 right-text">
          <button @click="clearEntries" type="reset" class=" btn ms-auto"> Cancel </button>
          <button @click="submitCredit" type="submit" class="col-2 aqua-button">Create</button>
        </div>
      </div>
    </form>

  </div>
</template>

<script>
  import { mapActions } from "vuex"

  export default {
    name: "NewRequest", 
    
    data: function() {
      return {
        formData: {
          employees: ["Yannik Hollmann", "Julia Kordick"], 
          imgEmployees: ["@/img/avatar.jpg", "@/img/avatar.jpg"],
          title: "",
          description: "",
          name: "",
          value: 2,
          surname: "",
          birthdate: "",
          nationality: "",
          address: "",
          netIncome: "",
          expenses: "",
          otherCredits: "",
          selectedEmployees: [],
        },
      }
    },

    methods: {
      firstDropdownOnChange(e) {
        this.formData.selectedEmployees[0] = e.target.value;
      },
      secondDropdownOnChange(e) {
        this.formData.selectedEmployees[1] = e.target.value;
      },
      clearEntries () {
        this.formData.title = "";
        this.formData.description = "";
        this.formData.name = "";
        this.formData.surname = "";
        this.formData.birthdate = ""
        this.formData.nationality = "",
        this.formData.address = "",
        this.formData.netIncome = "",
        this.formData.expenses = "",
        this.formData.otherCredits = "",
        this.formData.selectedEmployees.splice(0, arr.length);
      },

      ...mapActions(["CreditRequest"]),

      async submitCredit () {
        try {
          console.log(this.formData);
          await this.CreditRequest(JSON.stringify(this.formData));       
         this.$router.push("/homepage");

        } catch (error) {
          console.log(error)

        }
      } 
    },
  }

  
</script>
  
<style>

.container.new-request-container {
  border-radius: 15px;
  padding: 50px;
  background: #fff;
  margin-top: 50px;
}

.request-form input{
  margin-bottom: 10px;
}

.new-request-container .aqua-button {
  padding: 5px 10px;
  height: auto;
  width: auto;
  border-radius: 8px;
}

.new-request-container select.form-control {
  border-radius: 15px;
  border-color: #dcdcdc;
}

.right-text {
  text-align: end;
}

</style>