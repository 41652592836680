<template>
  <div class="container credit-container dashboard-container history-container">
    <h3>History</h3>
    <div class="row">
        <div class="col-md-2  col-sm-4 history-col">
            <p class="history-title">Name</p>
            <p
            v-for="(item, index) in names"
            :key="index"
            class="history-entry"
            >{{item}}</p>
        </div>
        <div class="col-md-6 col-sm-3 history-col">
            <p class="history-title">Detail</p>
            <p
            v-for="(item, index) in description"
            :key="index"
            class="history-entry"
            >{{item}}</p>
        </div>
        <div class="col-md-2  col-sm-3 history-col">
            <p class="history-title">Date</p>
            <p
            v-for="(item, index) in dates"
            :key="index"
            class="history-entry"
            >{{item}}</p>
        </div>
        <div class="col-2 history-col">
            <p class="history-title">Status</p>
            <p
            v-for="(item, index) in stati"
            :key="index"
            class="history-entry"
            >
            <span v-if="item[0] == 2"> <img src="@/img/status-ok.svg"></span>
            <span v-if="item[0] == 1"><img src="@/img/status-waiting.svg"></span>
            <span v-if="item[0] == 0"><img src="@/img/status-failed.svg"></span> 
            |
            <span v-if="item[0] == 2"><img src="@/img/status-ok.svg"></span>
            <span v-if="item[0] == 1"><img src="@/img/status-waiting.svg"></span>
            <span v-if="item[0] == 0"><img src="@/img/status-failed.svg"></span> 
            </p>
        </div>

    </div>
  </div>
</template>

<script>
  export default {
    name: "HistoryCredit",

    data: function () {
        return {
            // names: [],
            // description: [],
            // dates: [],
            // stati: [],

            // Mockup-Daten:

            names: ["Request #01", "Request #02","Request #01", "Request #02"],
            description: ["Lorem Ipsum dolor set amet dolores", "Amet et vici des colores","Lorem Ipsum dolor set amet dolores", "Amet et vici des colores"],
            dates: ["01.01.2022", "22.02.2022","01.01.2022", "22.02.2022"],
            stati: [ [0, 1 ] , [2, 1], [2, 2] , [1, 1] ], //0 false, 1 pending, 2 true


        }

    }
    
  }
</script>

<style>


.history-container {
    width: 100%;
    margin-top: 40px !important;
    padding: 40px 40px 0px 40px !important; /* SORRY :((( */
}

.history-container h3 {
    font-weight: 500;
    font-size: 18px;
}

.history-container .history-col {
    margin: 0;
    padding: 0;
}

.history-title{
    font-weight: 500;
    text-align: left;
}

.history-entry {
    text-align: left;
    border-top: 1px solid #DCDCDC;
    padding-top: 10px;
    width: 100%;
}

.history-container .history-entry img{
    width: 18px;
    height: 18px;
    margin: 0;
}

</style>
