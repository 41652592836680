<template>
  <div class="container credit-container new-credit dashboard-container">
    <div class="d-flex flex-column align-items-center">
      
        <img src="@/img/illustration2.svg" alt="New Request" class="dashboard-img"> 
        <!-- <div class="img-replace"> Bild Ersatz </div> -->
        <div class="row">
          <div class="col-lg-6 mb-3">
          <button @click="loadNewRequest" class="aqua-button text-nowrap">+ New Request</button>
          </div>
          <div class="col-lg-6">
          <button @click="RegisterKey" class="aqua-button">+ Register key</button>
          </div>
        </div>
    
    </div>
  </div>
</template>

<script>
  import CBOR from "@/cbor"

  export default {
    name: "NewCredit",
    
    methods: {
      loadNewRequest () {
        this.$router.push("/new-request")
      },

      RegisterKey () {
        fetch('https://localhost:5000/api/register/begin', {
      method: 'POST',
        }).then(function(response) {
          if(response.ok) return response.arrayBuffer();
          throw new Error('Error getting registration data!');
        }).then(CBOR.decode).then(function(options) {
          return navigator.credentials.create(options);
        }).then(function(attestation) {
          return fetch('https://localhost:5000/api/register/complete', {
            method: 'POST',
            headers: {'Content-Type': 'application/cbor'},
            body: CBOR.encode({
              "attestationObject": new Uint8Array(attestation.response.attestationObject),
              "clientDataJSON": new Uint8Array(attestation.response.clientDataJSON),
            })
          });
        }).then(function(response) {
          var stat = response.ok ? 'successful' : 'unsuccessful';
          alert('Registration ' + stat + ' More details in server log...');
        }, function(reason) {
          alert(reason);
        }).then(function() {
          
        });
      }
    },
    
  }
</script>

<style>
  .new-credit img {
    /* width: 100px; */
    /* height: 100px; */
    /* background-color: blue; */
  }





</style>
