import axios from "axios"

export default {
  state: {
    accessToken: "",
  },

  getters: {
    AccessToken: state => {
      return state.accessToken
    },
  },

  actions: {
    async LogIn ({ commit }, instance) {
      
      let response = await instance.post("user-login")
      await commit ("setToken", response.data.access_token)
      
    },
    async Credits ({ commit, getters }) {
      let response = await axios.get("credits", {
        headers: {
          Authorization: "Bearer " + getters.AccessToken ,
        }
      })
      await commit ("setCredits", response.data.credits)
    },
    async CreditRequest ({ getters }, formData) {
      await axios.post("credit", formData, {
        headers: {
          Authorization: "Bearer " + getters.AccessToken ,
        }
      }) 
    },
  },

  mutations: {
    setToken (state, token) {
      state.accessToken = token
    },
    setCredits (state, credits) {
      console.log("respones: ", credits)
    }
  },
}
