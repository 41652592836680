<template>
  <div>
    <div class="container dashboard">
      <div class="row justify-content-around">
        <div class="col-md-6 order-2 request-card">
          <PendingCredits></PendingCredits>
        </div>
      </div>
      <div class="row justify-content-center approve">
        <button @click="Approve" class="aqua-button">Approve</button>
      </div>
    </div>
    <div class="container new-request-container">
      <h3>Credit Overview</h3>
      <form class="mt-5 request-form form-group">
        <div class="row justify-content-between">
          <div class="col-6">
            <p>Title</p>
            <input value="Sample credit" disabled />
          </div>
          <div class="col-6">
            <p>Description</p>
            <input value="Lorem ipsum" disabled />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <p>Net Income</p>
            <input value="100000€" disabled />
          </div>
          <div class="col-6">
            <p>Expenses</p>
            <input value="50000€" disabled />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import PendingCredits from "@/views/PendingCredits";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import axios from "axios";
import CBOR from "@/cbor";

export default {
  name: "CreditOverview",

  data: function () {
    return {
      numberOfRequests: 1,
      credit: {},
    };
  },
  computed: {
    ...mapGetters({
      token: "AccessToken",
    }),
  },

  methods: {
    ...mapActions(["Credits"]),

    Approve() {
      fetch("https://localhost:5000/api/authenticate/begin", {
        method: "POST",
        headers: { Authorization: "Bearer " + this.token },
      })
        .then((response) => {
          if (response.ok) return response.arrayBuffer();
          throw new Error("No credential available to authenticate!");
        })
        .then(CBOR.decode)
        .then((options) => {
          return navigator.credentials.get(options);
        })
        .then((assertion) => {
          return fetch("https://localhost:5000/api/authenticate/complete", {
            method: "POST",
            headers: {
              "Content-Type": "application/cbor",
              Authorization: "Bearer " + this.token,
            },
            body: CBOR.encode({
              credentialId: new Uint8Array(assertion.rawId),
              authenticatorData: new Uint8Array(
                assertion.response.authenticatorData
              ),
              clientDataJSON: new Uint8Array(assertion.response.clientDataJSON),
              signature: new Uint8Array(assertion.response.signature),
            }),
          });
        })
        .then(
          (response) => {
            var stat = response.ok ? "successful" : "unsuccessful";
            alert("Authentication " + stat + " More details in server log...");
          },
          (reason) => {
            alert(reason);
          }
        );
    },
    async loadCredits() {
      try {
        await this.Credits();
      } catch (error) {
        console.log(error);
      }
    },
    async loadSingleCredit() {
      try {
        const response = await axios.get("credit/2", {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        });

        console.log(response.data.credit[0][0]);
        this.credit = response.data.credit[0][0];
      } catch (error) {
        console.log(error);
      }
    },
  },

  beforeMount() {
    this.loadCredits();
    this.loadSingleCredit();
  },

  components: {
    PendingCredits,
  },
};
</script>

<style>
.dashboard-container {
  border: 1px solid #dcdcdc;
  padding: 50px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 1px 5px rgba(196, 196, 196, 0.5);
  height: 100%;
}

.approve {
  margin-top: 2em;
}

.dashboard {
  margin-top: 50px;
}

.dashboard .aqua-button {
  max-width: 200px;
}

.img-replace,
.credit-container img.dashboard-img {
  height: 180px;
  margin: 20px 0;
  /* border: 1px solid grey; */
}
</style>
