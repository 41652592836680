import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "@/views/Dashboard";
import Login from "@/views/Login";
import Navbar from "@/views/Navbar";
import NewRequest from "@/views/NewRequest";
import CreditOverview from "@/views/CreditOverview";

Vue.use(VueRouter);

export default new VueRouter({
  mode: "hash",
  routes: [
    {
      path: "/homepage",
      component: Dashboard,
    },
    {
      path: "/login",
      component: Login,
    },
    //wieder raus löschen & in alle Views mit reinnehmen
    {
      path: "/navigation",
      component: Navbar,
    },
    {
      path: "/new-request",
      component: NewRequest,
    },
    {
      path: "/current-credit",
      component: CreditOverview,
    },
  ],
});
