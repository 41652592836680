<template>
  <div class="container credit-container dashboard-container pending-container">
    <!-- <div class="img-replace">Bild Ersatz</div> -->
    <div class="col-12">
      <img
        v-if="!numberOfRequests"
        src="@/img/illustration3.svg"
        alt="New Request"
        class="dashboard-img"
      />
      <img
        v-if="numberOfRequests"
        src="@/img/vouch-illustration3.gif"
        alt="New Request"
        class="dashboard-img"
        @click="Refresh"
      />
    </div>
    <!-- <p>No requests available</p>
    <p>Last update</p>
    <p>13.03.2022</p> -->
    <p v-if="numberOfRequests">
      <span class="number">{{ numberOfRequests }}</span> new request!
    </p>
    <button v-if="this.$route.path !== '/current-credit'" @click="Authorize" class="aqua-button">Authorize</button>
  </div>
</template>

<script>
import CBOR from "@/cbor.js";
import { mapGetters } from "vuex";
import axios from "axios"

export default {
  name: "PendingCredits",

  data: function () {
    return {
      numberOfRequests: 1,
    };
  },
  methods: {
    Refresh() {
      axios
        .get("waiting/2", {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          if (response.data.creditAuthenticated)
            this.numberOfRequests = 0;
          console.log(response.data.creditAuthenticated);
        });
    },
    Authorize() {
      this.$router.push("/current-credit");
    },
  },

  computed: {
    ...mapGetters({
      token: "AccessToken",
    }),
  },
};
</script>

<style>
.credit-container {
  text-align: center;
  /* height: 300px; */
  /* border: 3px solid blue; */
}

.pending-container .img-replace {
  height: 150px;
}

.number {
  background: #b1eaa4;
  padding: 5px 10px;
  border-radius: 15px;
  color: #fff;
}
</style>
