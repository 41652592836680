import Vuex from "vuex";
import Vue from "vue";
import createPersistedState from "vuex-persistedstate";
import auth from "@/store/modules/auth";
import VuexPersistence from 'vuex-persist'

// Load Vuex
Vue.use(Vuex);

const vuexSession = new VuexPersistence({
  storage: window.localStorage,
})

// Create store
export default new Vuex.Store({
  modules: {
    auth,
  },
  plugins: [vuexSession.plugin],
  // plugins: [createPersistedState()],
});
