<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light px-4 container">
    <img src="@/img/logo.svg" class="navbar-brand logo" @click="backToHome" alt="Logo">
    <div class="ms-auto" id="navbarNav">
        <ul class="navbar-nav align-items-center justify-content-between nav-ul">
            <li class="nav-item">
               <a href="#"> <img src="@/img/tools.svg" class="nav-img" alt="Manage"> </a>
            </li>
            <li class="nav-item">
                <a href="">
                    <a href="#"><img src="@/img/kelvin.jpeg" class="avatar" alt="Avatar"></a>
                </a>
            </li>
            <li class="nav-item">
                  <a href="#"><img src="@/img/logout.svg" class="nav-img" alt="Logout"></a>
            </li>
        </ul>
    </div>
    </nav>
</template>

<script>
  export default {
    name: "Navbar",
    methods: {
        backToHome () {
             this.$router.push('/')
        }
    },
  }
</script>
  
<style>
.avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    /* margin-right: 30px; */
}

.nav-ul {
    margin-top: 50px;
}

.nav-img {
    max-width: 50px;
    margin: 0 10px;
}

/* .navbar-brand {

} */

</style>